
<template>
  <div class="monitorViode">
    <div class="wxVideoTitle">
      <div class="wxVideoTitleInput" @click="showWxVideoTitleItemFunction">
        <div>{{ wxVideoTitleInputName }}</div>
        <div class="wxVideoTitleInputIcon">
          <i v-show="!showWxVideoTitleItem" class="el-icon-caret-bottom"></i>
          <i v-show="showWxVideoTitleItem" class="el-icon-caret-top"></i>
        </div>
      </div>
    </div>
    <div class="videoList">
      <div
        class="videoItem"
        v-for="(item, index) in list"
        :key="index"
        @click="getdataList(item)"
      >
        <div class="sxtImageUrl" v-if="item.valid == '1'">
          <img :src="sxtImageUrl" alt="" />
        </div>
        <div class="bzxsxtImageUrl" v-if="item.valid == '0'">
          <img :src="bzxsxtImageUrl" alt="" />
        </div>
        <div class="videoName">{{ item.name }}</div>
      </div>
    </div>
    <div class="wxVideoTitleItem" v-show="showWxVideoTitleItem">
      <div class="itemTextLiBox">
        <div class="itemTextLi">
          <div
            class="itemText"
            v-for="(item, index) in options"
            :key="index"
            :class="{ active: wxVideoTitleInputName == item.name }"
            @click="ProjectSwitching(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 播放页面 -->
    <div class="playPage" v-show="playPageObjL.playPageType">
      <div class="playPageName">
        <div class="playPageNameText dian1">
          {{ playPageObjL.titleName }}
        </div>
        <div class="playPageNameBut" @click="closePlayPage">关闭</div>
      </div>
      <!-- 播放器容器 -->
      <div class="videoBox">
        <div id="myVideo"></div>
      </div>
      <div class="direction">
        <div class="directionBxo">
          <div class="directionBxoS">
            <div></div>
            <div class="directionItem shangxia" @click="handleMouseDown(0)">
              <img v-show="imageUrlType" :src="playPageObjL.imageUrlS" alt="" />
              <img
                v-show="!imageUrlType"
                :src="playPageObjL.imageUrlS1"
                alt=""
              />
            </div>
            <div></div>
          </div>
          <div class="directionBxoZ">
            <div class="zuo" @click="handleMouseDown(2)">
              <img
                v-show="imageUrl2Type"
                :src="playPageObjL.imageUrlZ"
                alt=""
              />
              <img
                v-show="!imageUrl2Type"
                :src="playPageObjL.imageUrlZ1"
                alt=""
              />
            </div>
            <div class="directionItem">
              <img :src="playPageObjL.imageUrlZJ" alt="" />
            </div>
            <div class="zuo" @click="handleMouseDown(3)">
              <img
                v-show="imageUrl3Type"
                :src="playPageObjL.imageUrlY"
                alt=""
              />
              <img
                v-show="!imageUrl3Type"
                :src="playPageObjL.imageUrlY1"
                alt=""
              />
            </div>
          </div>
          <div class="directionBxoX">
            <div></div>
            <div class="directionItem shangxia" @click="handleMouseDown(1)">
              <img
                v-show="imageUrl1Type"
                :src="playPageObjL.imageUrlX"
                alt=""
              />
              <img
                v-show="!imageUrl1Type"
                :src="playPageObjL.imageUrlX1"
                alt=""
              />
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="Doubling">
        <div class="DoublingItem">
          <img
            @click="handleMouseDown(9)"
            :src="playPageObjL.imageUrljianhao"
            alt=""
          />
          <span> 变倍 </span>
          <img
            @click="handleMouseDown(8)"
            :src="playPageObjL.imageUrljiahao"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="diloeCentent" v-show="messageType">
      <div class="diloeCententTitle">
        <span style="margin-right: 10px">
          <i class="el-icon-warning"></i>
        </span>
        监控未连接
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {
  LoginToService,
  dmonitorChannels,
  dmonitorPtz,
} from "../../../api/visualization/index";
export default {
  components: {},
  data() {
    return {
      options: [],
      value: "",
      wxVideoTitleInputName: "",
      list: [],
      sxtImageUrl: require("../../../assets/visualization/video/SZTJK.png"),
      bzxsxtImageUrl: require("../../../assets/visualization/video/JKSX.png"),
      showWxVideoTitleItem: false,
      videoUrl: "", // 开发地址
      token: "", // 登录token
      datatree: [], // 树结构数据源
      // 播放页面
      playPageObjL: {
        titleName: "测试名称",
        Jessibuca: null,
        playPageType: false,
        imageUrlS: require("../../../assets/visualization/video/shang.png"),
        imageUrlS1: require("../../../assets/visualization/video/shang2.png"),
        imageUrlX: require("../../../assets/visualization/video/xia.png"),
        imageUrlX1: require("../../../assets/visualization/video/xia2.png"),
        imageUrlZ: require("../../../assets/visualization/video/zuo.png"),
        imageUrlZ1: require("../../../assets/visualization/video/zuo2.png"),
        imageUrlY: require("../../../assets/visualization/video/you.png"),
        imageUrlY1: require("../../../assets/visualization/video/you2.png"),
        imageUrlZJ: require("../../../assets/visualization/video/fuwei.png"),
        imageUrljiahao: require("../../../assets/visualization/video/jiahao.png"),
        imageUrljianhao: require("../../../assets/visualization/video/jianhao.png"),
      },
      primarykeyId: "",
      thoroughfareId: "",
      imageUrlType: true,
      imageUrl1Type: true,
      imageUrl2Type: true,
      imageUrl3Type: true,
      messageType: false,
    };
  },
  created() {},
  mounted() {
    let that = this;
    that.loadToken();
    if (location.host == "39.106.228.40") {
      that.videoUrl = process.env.VUE_APP_BASE_VIDEOURL1;
    } else {
      that.videoUrl = process.env.VUE_APP_BASE_VIDEOURL;
    }
  },
  beforeDestroy() {},
  // 计算属性
  computed: {},
  methods: {
    showWxVideoTitleItemFunction() {
      this.showWxVideoTitleItem = !this.showWxVideoTitleItem;
    },
    ProjectSwitching(item) {
      this.wxVideoTitleInputName = item.name;
      this.list = [];
      this.list = item.items;
      this.showWxVideoTitleItem = false;
    },
    // 获取树结构数据源
    loadToken() {
      let that = this;
      let originalString = "admin";
      let encodedString = btoa(originalString);
      // http://8.147.130.99:8080/LoginToService
      // let url =
      //   that.videoUrl +
      //   "/dmonitor/LoginToService?username=admin&password=" +
      //   encodedString;
      // axios
      //   .get(url, data)
      //   .then(function (response) {
      //     that.token = response.data.token;
      //     that.loadTreeData();
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
      let params = {
        username: "admin",
        password: encodedString,
      };
      LoginToService(params)
        .then(function (response) {
          // console.log("登录接口返回数据", response);
          that.token = response.data.token;
          that.loadTreeData();
        })
        .catch();
    },
    loadTreeData() {
      let that = this;
      // let data = {};
      // let url =
      //   that.videoUrl +
      //   "/dmonitor/org/Channels?token=" +
      //   that.token +
      //   "&httpflv=1&ws=1";
      // axios
      //   .get(url, data)
      //   .then(function (response) {
      //     const newData = that.treeLoop(response.data.tree);
      //     that.datatree = newData;
      //     console.log("数据结构", that.datatree);
      //     that.options = that.datatree[0].items[0].items;
      //     that.wxVideoTitleInputName = that.options[0].name;
      //     that.list = that.options[0].items;
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
      let params = {
        token: that.token,
        httpflv: "1",
        ws: "1",
      };
      dmonitorChannels(params)
        .then(function (response) {
          const newData = that.treeLoop(response.data.tree);
          that.datatree = newData;
          // console.log("数据结构", that.datatree);
          that.options = that.datatree[0].items[0].items;
          that.wxVideoTitleInputName = that.options[0].name;
          that.list = that.options[0].items;
        })
        .catch();
    },
    // 树 数据层级处理
    treeLoop(arr) {
      if (!arr) arr = [];
      var newArr = JSON.parse(JSON.stringify(arr));
      var lastItem = undefined;
      const _loop = function (data) {
        if (!data) data = [];
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          if (item.type == 1) {
            lastItem = item;
            if (!item.items) item.items = [];
            _loop(item.items);
          }
          if (item.type == 2) {
            var children = item.items;
            var lastItemChildren = lastItem.items || [];
            children.forEach((child) => {
              lastItemChildren.push(child);
            });
            lastItem.items = lastItemChildren;
            lastItem.levelNumber = 2;
          }
        }
      };
      _loop(newArr);
      const _loop1 = function (data) {
        if (!data) data = [];
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          if (item.items) {
            item.items = item.items.filter((itm) => itm.type != 2);
            _loop1(item.items);
          }
        }
      };
      _loop1(newArr);
      // for (let i = 0; i < arr.length; i++) {
      //   let item = arr[i];
      //   let newItem = { ...item };
      //   var type = item.type;
      //   console.log(type);
      //   if (type == 1 || type === 3) {
      //     newItem.items = this.treeLoop(item.items);
      //     result.push(newItem);
      //   }
      //   if (type == 2) {
      //     let children = [];
      //     const transfer = (list) => {
      //       if (!list) list = [];
      //       list.forEach((obj) => {
      //         if (obj.url) {
      //           children.push(obj);
      //         } else {
      //           transfer(obj.items);
      //         }
      //       });
      //     };
      //     transfer(item.items);
      //     newItem.items = children;
      //     newItem.levelNumber = 2;
      //     result.push(newItem);
      //   }
      // }
      return newArr;
    },
    //点击播放视频
    getdataList(row) {
      let that = this;
      console.log(row);
      if (row.valid == 0) {
        that.messageType = true;
        setTimeout(() => {
          that.messageType = false;
        }, 1000);
        return;
      }
      this.playPageObjL.titleName =
        this.wxVideoTitleInputName + " - " + row.name;
      this.playPageObjL.playPageType = true;
      this.primarykeyId = row.device;
      this.thoroughfareId = row.channelNo;
      let dz = that.videoUrl + "/liveVideo/";
      let terrUrl = dz + row.httpflv;
      let ID = "myVideo";
      this.playPageObjL.Jessibuca = this.create(ID, terrUrl);
    },
    create(id, url) {
      var container = document.getElementById(id);
      let jessibuca = new window.Jessibuca(
        Object.assign({
          container: container,
          autoWasm: true, // 在使用MSE或者Webcodecs 播放H265的时候，是否自动降级到wasm模式。
          background: "", //背景图片
          controlAutoHide: false, //底部控制台是否自动隐藏
          debug: false, //是否开启控制台调试打印
          // decoder: "/jessibuca/decoder.js",
          hasVideo: true, //  是否开启控制台调试打印
          heartTimeout: 5, //设置超时时长, 单位秒播放中途,如果超过设定时长无数据返回,则回调timeout事件
          heartTimeoutReplay: true, //是否开启心跳超时之后自动再播放
          heartTimeoutReplayTimes: 3, //重试次数  heartTimeoutReplay 重试失败之后，不再重新播放视频地址。如果想无限次重试，可以设置为-1
          hiddenAutoPause: false, //是否开启当页面的'visibilityState'变为'hidden'的时候，自动暂停播放。
          hotKey: false, //是否开启键盘快捷键 esc -> 退出全屏；arrowUp -> 声音增加；arrowDown -> 声音减少；
          isFlv: false, //当为true的时候：ws协议不检验是否以.flv为依据，进行协议解析。
          isFullResize: false, //当为true的时候：视频画面做等比缩放后,完全填充canvas区域,画面不被拉伸,没有黑边,但画面显示不全
          isNotMute: false, // 是否开启声音，默认是关闭声音播放的。
          isResize: false, //当为true的时候：视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。 当为false的时候：视频画面完全填充canvas区域,画面会被拉伸。
          keepScreenOn: false, //开启屏幕常亮，在手机浏览器上, canvas标签渲染视频并不会像video标签那样保持屏幕常亮。PC端不会生效，仅手机端生效
          loadingText: "请稍等, 数据连接中......", // 视频加载转圈时的提示文字
          loadingTimeout: 10, //当play()的时候，如果没有数据返回，则回调
          loadingTimeoutReplay: true, ///是否开启loading超时之后自动再播放
          loadingTimeoutReplayTimes: 3, //loadingTimeoutReplay 重试失败之后，不再重新播放视频地址。
          operateBtns: {
            // 配置操作按钮 其中
            fullscreen: false, //全屏按钮
            screenshot: false, //截图按钮
            play: false, //播放暂停按钮
            audio: false, //声音按钮
            record: false, //录制按钮
          },
          recordType: "webm", //默认录制的视频格式
          rotate: 0, //设置旋转角度
          showBandwidth: false, // 显示网速
          supportDblclickFullscreen: false, // 是否支持屏幕的双击事件，触发全屏，取消全屏事件。
          timeout: 10, //设置超时时长, 单位秒在连接成功之前(loading)和播放中途(heart),如果超过设定时长无数据返回,则回调timeout事件
          useMSE:
            location.hostname !== "localhost" && location.protocol !== "https:", //是否开启MediaSource硬解码
          useWCS:
            location.hostname === "localhost" || location.protocol === "https", //是否开启Webcodecs硬解码
          useWebFullScreen: false, //是否使用web全屏(旋转90度)（只会在移动端生效）。
          videoBuffer: 0.2, // 设置最大缓冲时长，单位秒，播放器会自动消除延迟。
          wasmDecodeErrorReplay: true, // 是否开启解码失败重新播放
          wcsUseVideoRender: true, //webcodecs硬解码是否通过video标签渲染
        })
      );
      jessibuca.play(url);
      return jessibuca;
    },
    // 控制球机方向
    handleMouseDown(index) {
      if (index == 0) {
        this.imageUrlType = false;
      }
      if (index == 1) {
        this.imageUrl1Type = false;
      }
      if (index == 2) {
        this.imageUrl2Type = false;
      }
      if (index == 3) {
        this.imageUrl3Type = false;
      }
      let that = this;
      // let data = {};
      // let url =
      //   that.videoUrl +
      //   "/dmonitor/ptz?deviceid=" +
      //   that.primarykeyId +
      //   "&channel=" +
      //   that.thoroughfareId +
      //   "&op=" +
      //   index +
      //   "&start=1&speed=2";
      // axios
      //   .get(url, data)
      //   .then(function (response) {
      //     setTimeout(() => {
      //       that.handleMouseUp(index);
      //     }, 1000);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
      let params = {
        deviceid: that.primarykeyId,
        channel: that.thoroughfareId,
        op: index,
        start: 1,
        speed: 2,
      };
      dmonitorPtz(params)
        .then(function (response) {
          setTimeout(() => {
            that.handleMouseUp(index);
          }, 1000);
        })
        .catch();
    },
    // 结束控制球机方向
    handleMouseUp(index) {
      let that = this;
      if (index == 0) {
        this.imageUrlType = true;
      }
      if (index == 1) {
        this.imageUrl1Type = true;
      }
      if (index == 2) {
        this.imageUrl2Type = true;
      }
      if (index == 3) {
        this.imageUrl3Type = true;
      }
      // let data = {};
      // let url =
      //   that.videoUrl +
      //   "/dmonitor/ptz?deviceid=" +
      //   that.primarykeyId +
      //   "&channel=" +
      //   that.thoroughfareId +
      //   "&op=" +
      //   index +
      //   "&start=0&speed=2";
      // axios
      //   .get(url, data)
      //   .then(function (response) {
      //     // console.log("控制球机方向", response);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
      let params = {
        deviceid: that.primarykeyId,
        channel: that.thoroughfareId,
        op: index,
        start: 0,
        speed: 2,
      };
      dmonitorPtz(params)
        .then(function (response) {})
        .catch();
    },
    closePlayPage() {
      this.playPageObjL.Jessibuca.destroy()
        .then(() => {
          console.log("Player has been destroyed");
        })
        .catch((error) => {
          console.error("Error destroying player:", error);
        });
      this.playPageObjL.playPageType = false;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  width: 100vw;
  height: 100vh;
  background-color: #10121f;
  padding: 1vh;
}
body .monitorViode {
  width: 100%;
  height: 100%;
  background-color: #10121f;
  padding-top: 20px;
  position: relative;
}
.wxVideoTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 4%;
}
.wxVideoTitleInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  height: 40px;
  background-image: url(../../../assets/visualization/video/input.png);
  background-size: 100% 100%;
  color: #e2e2e2;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Microsoft YaHei", Arial, sans-serif !important;
}
.wxVideoTitleInputIcon {
  font-size: 20px !important;
}
.videoList {
  width: 100%;
  margin: 10px 0;
  height: 90vh;
  padding-bottom: 20px;
  overflow: auto;
  border-top: 1px solid #666666;
}
.videoItem {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 4%;
  border-bottom: 1px solid #666666;
  color: #e2e2e2;
  font-size: 13.997px !important;
  font-weight: 500 !important;
  font-family: "Microsoft YaHei", Arial, sans-serif !important;
}
.sxtImageUrl {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.sxtImageUrl img {
  width: 100%;
}
.bzxsxtImageUrl {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.bzxsxtImageUrl img {
  width: 100%;
}
.wxVideoTitleItem {
  width: 100%;
  padding: 15px 4%;
  position: absolute;
  top: 70px;
  left: 0;
}
.itemTextLiBox {
  width: 100%;
  height: 70vh;
  background-image: url(../../../assets/visualization/video/inputItem.png);
  background-size: 100% 100%;
  padding: 10px;
}
.itemTextLi {
  width: 100%;
  height: 100%;
  padding: 10px 0;
  overflow-y: scroll;
}
.itemText {
  width: 100%;
  padding: 10px;
  color: #e2e2e2;
  font-size: 13.997px !important;
  font-weight: 500 !important;
}
.active {
  background-color: #052f55;
}
// 播放页面
.playPage {
  position: absolute;
  top: 10%;
  left: 3%;
  width: 94%;
  height: 80%;
  background-color: #3e3e3e;
  z-index: 999;
}
.playPageName {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 15px 10px;
  position: relative;
  box-sizing: border-box;
}
.playPageNameBut {
  // position: absolute;
  // top: 10px;
  // left: 15px;
  width: 15%;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 3px 10px;
  margin-left: 5px;
}
.playPageNameText {
  width: calc(80% - 5px);
}
.videoBox {
  width: 100%;
  height: 300px !important;
  border-top: 1px solid #666666;
  border-bottom: 1px solid #666666;
}
#my-video {
  width: 100%;
  height: 100% !important;
}
.direction {
  width: 100%;
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.directionBxo {
  width: 60%;
  height: 60%;
}
.directionBxoS {
  width: 100%;
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.directionBxoZ {
  width: 100%;
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.directionBxoX {
  width: 100%;
  height: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.directionBxoS div {
  flex: 1;
  color: #fff;
  text-align: center;
}
.directionBxoZ div {
  flex: 1;
  color: #fff;
  text-align: center;
}
.directionBxoX div {
  flex: 1;
  color: #fff;
  text-align: center;
}

.directionBxoS div > img {
  width: 100%;
}
.directionBxoZ div > img {
  width: 100%;
}
.directionBxoX div > img {
  width: 100%;
}
.directionItem {
  width: 40% !important;
}
.zuo img {
  width: 50px !important;
}
.shangxia img {
  width: 100px !important;
}
.Doubling {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DoublingItem {
  width: 50%;
  padding: 5px 15px;
  border-radius: 8px;
  color: #fff;
  background-color: #2c2f3e;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DoublingItem img {
  width: 20px;
}
.diloeCentent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.diloeCententTitle {
  width: 50%;
  height: 40px;
  background-color: #fdf6ec;
  border-radius: 10px;
  line-height: 40px;
  margin-top: 30%;
  color: #e8a23c;
  padding: 0 10px;
}
.dian1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 限制在两行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
</style>
